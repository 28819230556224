























































































































































































































































































































































































































































.redDotBox {
  margin-left: 13px;
  padding: 19px 0;
  color: #d90119;
  cursor: pointer;
  /deep/ .el-badge__content.is-dot {
    height: 8px !important;
  }
}
